<template>
  <div id="app" class="musicse">
    <Nav />
    <div class="mt-50 musicse-hz">
      <div class="music-top">
        <img class="top-img" :src="require(`@/assets/banner/banner-10${$i18n.locale == 'zh'?'z':'e'}.png`)" alt="" />
        <div class="music-horizontal flex around">
          <div class="music-horizontal-hz" 
            v-for="item in list" :key="item.id"
            @click="horizontal(item)"
            :class="type == item.id?'active':''"
          >
            <p class="music-hz-p1">{{item.name}}</p>
            <div class="music-hz-yuan"></div>
          </div>
        </div>
      </div>
      <div class="music" v-if="type == 2 && $i18n.locale == 'zh'">
          <div class="music-img" v-for="(item,key) in 6" :key="key">
            <img v-lazy="require(`@/assets/join/join-${key+11}.png`)" alt="">
          </div>
      </div>
      <div class="music" v-if="type == 2 && $i18n.locale == 'en'">
          <div class="music-img" v-for="(item,key) in 8" :key="key">
            <img v-lazy="require(`@/assets/join/${key+1}.png`)" alt="">
          </div>
      </div>
      <!-- <div class="music" v-if="type == 1">
          <div class="music-img" v-for="(item,key) in 18" :key="key">
            <img v-lazy="require(`@/assets/season/${key+3 < 10 ? '0' + (key+3) : (key+3)}.jpg`)" alt="">
          </div>
      </div> -->
      <div class="music" v-if="type == 3">
          <div class="music-img" v-for="(item,key) in 43" :key="key">
            <img v-lazy="require(`@/assets/handbook/2022big${key+1}.png`)" alt="">
          </div>
      </div>
       <div class="music" v-if="type == 4">
          <div class="music-img" v-for="(item,key) in 37" :key="key">
            <img v-lazy="require(`@/assets/handbook2023/2023big${key+1}.png`)" alt="">
          </div>
      </div>
      <div class="music" v-if="type == 5">
          <div class="music-img" v-for="(item,key) in 29" :key="key">
            <img v-lazy="require(`@/assets/handbook2024/2024big${key+1}.png`)" alt="">
          </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
        type:5,
        list:[
            // {
            //     id:1,
            //     name:'2020-2021'
            // },
            {
                id:2,
                name:'2021-2022'
            },
            {
                id:3,
                name:'2022-2023'
            },
             {
                id:4,
                name:'2023-2024'
            },
            {
                id:5,
                name:'2024-2025'
            },
        ]
    };
  },
  computed: {},
  created() {},
  mouthed() {},
  methods: {
      horizontal(val){
          this.type = val.id
      }
  },
};
</script>



<style lang="scss" scoped>
.musicse-hz{
  background-color: #F2F6F7;
}
.music-top {
  position: relative;
  .music-horizontal {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 17%;
    border-bottom: 1px solid #fff;
    .music-horizontal-hz {
      position: relative;
      top: 5px;
      width: 92px;
      .music-hz-p1 {
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        height: 26px;
        text-align: center;
      }
      .music-hz-yuan {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #27E7D6;
        margin: 0 auto;
      }
    }
    .active {
      position: relative;
      top: 5px;
      .music-hz-p1 {
        color: #fff;
        font-size: 16px;
        line-height: 24px;
      }
      .music-hz-yuan {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #27E7D6;
        margin: 0 auto;
      }
      .music-hz-yuan::after {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: rgba(39, 231, 214, 0.4);
        position: relative;
        top: -3px;
        left: -3px;
      }
    }
  }
}
.music {
  padding: 22px 6px;
  font-size: 0;
  .music-img{
    margin-bottom:16px;
  }
}
.musicse{
  img{
    max-width: 100%;
  }
}
</style>

